import { useEffect, useRef, useState } from "react";
import { View, TextInput, GestureResponderEvent } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Divider, makeStyles } from "@rneui/themed";
import { Input as BaseInput } from "@rneui/base";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { useFormik } from "formik";
import { get, set, isEqual } from "lodash";

import { useAppDispatch } from "common/redux";
import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";
import {
  useUpdateMemberDataMutation,
  useUpdateMemberDetailsMutation
} from "common/services/MemberRegistrationService";

import {
  EMAIL_VALIDATION_REGEXP,
  PHONE_VALIDATION_REGEXP,
  areAddressesEqual,
  getStates,
  isFalsy,
  isStateAbbreviation,
  isValidUSPostalCode,
  unmaskPhoneNumber,
  validateFieldLength
} from "common/helpers/helpers";
import { Alert_show } from "common/helpers/AlertHelper";

import PatientHeader from "../../components/myaccount/PatientHeader";
import Input from "../../components/ui/Input";
import PhoneInput from "../../components/ui/PhoneInput";
import ScreenContainer from "../../components/ui/ScreenContainer";
import Button from "../../components/ui/Button";
import { MyAccountStackParamList } from "../../navigation/MyAccountStackNavigator";
import PickerModal from "../../components/ui/modal/PickerModal";
import useScreenType, { ScreenTypeEnum } from "../../hooks/useScreenType";
import Text from "../../components/ui/Text";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import Spacing from "../../components/ui/Spacing";
import ActivityIndicator from "../../components/ui/ActivityIndicator";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import MemberTypeInner from "common/types/common/MemberTypeInner";
import LocalizedStrings from "../../helpers/LocalizedStrings";

const STREET1 = "address.street1";
const STREET2 = "address.street2";
const CITY = "address.city";
const STATE = "address.state";
const POSTAL_CODE = "address.postal_code";

type FormType = Partial<MemberTypeInner>;

const stateFullDropdownOptions = getStates(true);

const PersonalInfoScreen = ({ route }) => {
  const routeValues = route?.params?.values;
  const dispatch = useAppDispatch();
  const navigation = useNavigation<NavigationProp<MyAccountStackParamList>>();

  const styles = useStyles();
  const textStyles = useTextStyles();
  const { type } = useScreenType();

  const lastNameRef = useRef<TextInput & BaseInput>();
  const emailRef = useRef<TextInput & BaseInput>();
  const phoneRef = useRef<TextInput & BaseInput>();
  const mobileRef = useRef<TextInput & BaseInput>();
  const street1Ref = useRef<TextInput & BaseInput>();
  const street2Ref = useRef<TextInput & BaseInput>();
  const cityRef = useRef<TextInput & BaseInput>();
  const stateRef = useRef<TextInput & BaseInput>();
  const zipRef = useRef<TextInput & BaseInput>();

  const [stateModalVisible, setStateModalVisible] = useState<boolean>(false);
  const [componentWidth, setComponentWidth] = useState<number>();

  const { data: patient, isLoading } = useGetAuthenticatedMember(undefined, [
    MemberLinkedEntitiesEnum.METADATA
  ]);

  const [
    updatePatientMutation,
    { isLoading: updateMemberLoading, isSuccess: updateMemberSuccess }
  ] = useUpdateMemberDetailsMutation();

  const [
    updateMemberDataMutation,
    { isLoading: updateMemberDataLoading, isSuccess: updateMemberDataSuccess }
  ] = useUpdateMemberDataMutation();

  const [initialValues, setInitialValues] = useState<FormType>(
    patient?.patient
  );

  useEffect(() => {
    if (isFalsy(patient)) return;

    const initialValues = { ...patient.patient };

    setInitialValues(initialValues);
  }, [patient, routeValues]);

  const validate = (values) => {
    const errors = {};

    if (
      values?.contact_info?.landline?.value &&
      !PHONE_VALIDATION_REGEXP.test(
        unmaskPhoneNumber(values?.contact_info?.landline?.value)
      )
    ) {
      errors["phone"] = LocalizedStrings.common.validations.phoneNumber;
    }

    if (
      values?.contact_info?.mobile?.value &&
      !PHONE_VALIDATION_REGEXP.test(
        unmaskPhoneNumber(values?.contact_info?.mobile?.value)
      )
    ) {
      errors["mobile"] = LocalizedStrings.common.validations.mobileNumber;
    }

    if (
      values?.contact_info?.email?.value &&
      !EMAIL_VALIDATION_REGEXP.test(values?.contact_info?.email?.value)
    ) {
      errors["email"] = LocalizedStrings.common.validations.invalidEmail;
    }

    if (!validateFieldLength(2, 50, values["first"])) {
      errors["first"] = LocalizedStrings.common.validations.betweenCharacters
        .replace("{{FROM}}", "2")
        .replace("{{TO}}", "50");
    }

    if (!validateFieldLength(2, 50, values["last"])) {
      errors["last"] = LocalizedStrings.common.validations.betweenCharacters
        .replace("{{FROM}}", "2")
        .replace("{{TO}}", "50");
    }

    if (values["address"]) {
      const street1 = get(values, STREET1, null);
      const street2 = get(values, STREET2, null);
      const city = get(values, CITY, null);
      const state = get(values, STATE, null);
      const postal_code = get(values, POSTAL_CODE, null);

      if (!street1) {
        set(errors, STREET1, LocalizedStrings.common.validations.required);
      }
      if (!validateFieldLength(2, 50, street1)) {
        set(
          errors,
          STREET1,
          LocalizedStrings.common.validations.betweenCharacters
            .replace("{{FROM}}", "2")
            .replace("{{TO}}", "50")
        );
      }

      if (street2 && !validateFieldLength(1, 50, street2)) {
        set(
          errors,
          STREET2,
          LocalizedStrings.common.validations.upToCharacters.replace(
            "{{TO}}",
            "50"
          )
        );
      }

      if (!city) {
        set(errors, CITY, LocalizedStrings.common.validations.required);
      }
      if (!validateFieldLength(2, 50, city)) {
        set(
          errors,
          CITY,
          LocalizedStrings.common.validations.betweenCharacters
            .replace("{{FROM}}", "2")
            .replace("{{TO}}", "50")
        );
      }

      if (!state) {
        set(errors, STATE, LocalizedStrings.common.validations.required);
      } else if (!isStateAbbreviation(state)) {
        set(errors, STATE, LocalizedStrings.common.validations.validState);
      }

      if (!postal_code) {
        set(errors, POSTAL_CODE, LocalizedStrings.common.validations.required);
      }

      if (!isValidUSPostalCode(postal_code)) {
        set(
          errors,
          POSTAL_CODE,
          LocalizedStrings.common.validations.validPostalCode
        );
      }
    }

    return errors;
  };

  const onSubmit = async (values, initialValues) => {
    const changes: Partial<MemberTypeInner> = {};

    const processValue = (key) => {
      if (key === "address") {
        if (!areAddressesEqual(initialValues[key], values[key])) {
          changes[key] = values[key];
        }
      } else if (!isEqual(initialValues[key], values[key])) {
        let value = values[key];
        if (key === "phone" || key === "mobile" || key === "landline") {
          value = unmaskPhoneNumber(value);
        }
        if (key === "contact_info") {
          changes[key] = value;
          if (changes[key]) {
            const unmaskedLandline = unmaskPhoneNumber(
              changes[key]?.landline?.value
            );
            const unmaskedMobile = unmaskPhoneNumber(
              changes[key]?.mobile?.value
            );
            if (unmaskedLandline) {
              changes[key]["landline"] = {
                ...values[key]?.landline,
                value: unmaskedLandline
              };
            }

            if (unmaskedMobile) {
              changes[key]["mobile"] = {
                ...values[key]?.mobile,
                value: unmaskedMobile
              };
            }
          }
        } else changes[key] = value.trim();
      }
    };
    Object.keys(values).forEach((key) => {
      processValue(key);
    });

    if (Object.keys(changes)?.length > 0) {
      if (changes.first) {
        changes.first = changes.first?.trim();
      }
      if (changes.last) {
        changes.last = changes.last?.trim();
      }
      if (changes.middle) {
        changes.middle = changes.middle?.trim();
      }

      if (changes.address) {
        changes.address.street1 = changes.address?.street1?.trim();
        if (!isFalsy(changes.address?.street2)) {
          changes.address.street2 = changes.address?.street2?.trim();
        }
        changes.address.city = changes.address?.city?.trim();

        // hardcode country to US
        changes.address.country = "US";
      }

      if (
        JSON.stringify(initialValues?.contact_info) !==
        JSON.stringify(values?.contact_info)
      ) {
        await updateMemberDataMutation({
          member_id: patient?.patient?.patient_id,
          contact_info: values.contact_info
        });
      }

      delete changes.contact_info;
      if (Object.keys(changes).length > 0)
        await updatePatientMutation({
          patient_id: patient.patient.patient_id,
          patient: changes
        });
    }
  };

  const formik = useFormik<FormType>({
    validate,
    initialValues,
    onSubmit: (values) => {
      onSubmit(values, initialValues || {}).catch((error) => {});
    },
    enableReinitialize: true
  });

  const setFieldValue = (key, value) => {
    formik.setFieldValue(key, value).catch((error) => {});
  };

  useEffect(() => {
    if (routeValues?.address?.state) {
      setFieldValue("address.state", routeValues?.address?.state);
    }
  }, [routeValues?.address?.state]);

  useEffect(() => {
    if (updateMemberDataSuccess || updateMemberSuccess) {
      Alert_show({
        dispatch,
        title: LocalizedStrings.screens.editPersonalInfo.successModalTitle,
        content: LocalizedStrings.screens.editPersonalInfo.successModalContent,
        buttons: [
          {
            text: LocalizedStrings.common.close,
            onPress: () => navigation.goBack()
          }
        ]
      });
    }
  }, [updateMemberDataSuccess, updateMemberSuccess]);

  const contactInfo = formik?.values?.contact_info;

  return (
    <ScreenContainer>
      {isLoading ? (
        <ActivityIndicator style={styles.flex1} />
      ) : (
        <View
          onLayout={(event) => {
            setComponentWidth(event.nativeEvent.layout.width);
          }}
          style={[styles.flex1, styles.row]}
        >
          {type !== ScreenTypeEnum.PHONE && componentWidth > 800 && (
            <View style={[styles.flex1, styles.tabletTitleContainer]}>
              <Text h3 style={textStyles.colorDarkGreyBlue}>
                {LocalizedStrings.screens.editPersonalInfo.title}
              </Text>
            </View>
          )}
          <KeyboardAwareScrollView
            style={styles.flex1}
            keyboardDismissMode="on-drag"
            enableResetScrollToCoords={false}
            enableOnAndroid={true}
          >
            <PatientHeader />

            <View style={styles.inputContainer}>
              <Input
                testID="first_name"
                label={LocalizedStrings.screens.personalInformation.firstName.toUpperCase()}
                accessibilityLabel={"First Name"}
                value={formik.values?.first}
                onChangeText={formik.handleChange("first")}
                autoFocus
                textContentType="givenName"
                enterKeyHint="next"
                errorMessage={formik.errors?.first}
                onSubmitEditing={() => lastNameRef?.current?.focus()}
              />
              <Input
                testID="last_name"
                ref={lastNameRef}
                label={LocalizedStrings.screens.personalInformation.lastName.toUpperCase()}
                accessibilityLabel={"Last Name"}
                value={formik.values?.last}
                onChangeText={formik.handleChange("last")}
                textContentType="familyName"
                enterKeyHint="next"
                errorMessage={formik.errors?.last}
                onSubmitEditing={() => emailRef?.current?.focus()}
              />
              {contactInfo?.email?.value && (
                <Input
                  testID="email"
                  ref={emailRef}
                  label={LocalizedStrings.screens.personalInformation.emailAddress.toUpperCase()}
                  value={contactInfo?.email?.value ?? ""}
                  onChangeText={(value) => {
                    formik.setFieldValue(`contact_info.email.value`, value);
                  }}
                  inputMode="email"
                  enterKeyHint="next"
                  errorMessage={formik.errors.email}
                  onSubmitEditing={() => phoneRef?.current?.focus()}
                  disabled
                />
              )}
              <PhoneInput
                testID="phone"
                ref={phoneRef}
                label={LocalizedStrings.screens.personalInformation.phoneNumber.toUpperCase()}
                accessibilityLabel={"Phone Number"}
                value={
                  contactInfo?.landline?.value ?? patient?.patient?.phone ?? ""
                }
                onChangeText={(value) => {
                  formik.setFieldValue(`contact_info.landline.value`, value);
                }}
                enterKeyHint="next"
                errorMessage={formik.errors.phone}
                onSubmitEditing={() => mobileRef?.current?.focus()}
              />
              <PhoneInput
                testID="mobile"
                ref={mobileRef}
                label={LocalizedStrings.screens.personalInformation.mobileNumber.toUpperCase()}
                accessibilityLabel={"MOBILE NUMBER"}
                value={
                  contactInfo?.mobile?.value ?? patient?.patient?.mobile ?? ""
                }
                onChangeText={(value) => {
                  formik.setFieldValue(`contact_info.mobile.value`, value);
                }}
                enterKeyHint="next"
                errorMessage={formik.errors.mobile}
                onSubmitEditing={() => street1Ref?.current?.focus()}
              />
              <Input
                testID="street1"
                ref={street1Ref}
                label={LocalizedStrings.screens.personalInformation.street1.toUpperCase()}
                accessibilityLabel={"Street 1"}
                value={formik.values?.address?.street1}
                onChangeText={formik.handleChange("address.street1")}
                textContentType="fullStreetAddress"
                enterKeyHint="next"
                errorMessage={formik.errors.address?.street1}
                onSubmitEditing={() => street2Ref?.current?.focus()}
                disabled
              />
              <Input
                testID="street2"
                ref={street2Ref}
                label={LocalizedStrings.screens.personalInformation.street2.toUpperCase()}
                accessibilityLabel={"Street 2"}
                value={formik.values?.address?.street2}
                onChangeText={formik.handleChange("address.street2")}
                textContentType="fullStreetAddress"
                enterKeyHint="next"
                errorMessage={formik.errors.address?.street2}
                onSubmitEditing={() => cityRef?.current?.focus()}
                disabled
              />
              <Input
                testID="city"
                ref={cityRef}
                label={LocalizedStrings.screens.personalInformation.city.toUpperCase()}
                accessibilityLabel={"City"}
                value={formik.values?.address?.city}
                onChangeText={formik.handleChange("address.city")}
                textContentType="addressCity"
                enterKeyHint="next"
                errorMessage={formik.errors.address?.city}
                onSubmitEditing={() => zipRef?.current?.focus()}
                disabled
              />
              <Input
                testID="state"
                ref={stateRef}
                label={LocalizedStrings.screens.personalInformation.state.toUpperCase()}
                accessibilityLabel={"State"}
                value={formik.values?.address?.state}
                textContentType="addressState"
                enterKeyHint="next"
                errorMessage={formik.errors.address?.state}
                onSubmitEditing={() => stateRef?.current?.focus()}
                disabled
                editable={false}
                /*rightIcon={
                  <TouchableOpacity onPress={() => setStateModalVisible(true)}>
                    <Icon
                      name="edit"
                      size={20}
                      color="black"
                      accessibilityLabel={'State Icon'}
                    />
                  </TouchableOpacity>
                }*/
              />
              <Input
                testID="zip"
                ref={zipRef}
                label={LocalizedStrings.screens.personalInformation.zipCode.toUpperCase()}
                accessibilityLabel={"Zip Code"}
                value={formik.values?.address?.postal_code}
                onChangeText={formik.handleChange("address.postal_code")}
                inputMode="numeric"
                enterKeyHint="done"
                maxLength={5}
                disabled
              />
            </View>
            <Spacing vertical={4} />
          </KeyboardAwareScrollView>
        </View>
      )}

      <PickerModal
        visible={stateModalVisible}
        handleModalClose={() => setStateModalVisible(false)}
        data={stateFullDropdownOptions}
        selectedItems={[formik.values?.address?.state]}
        onSelectedItemsChange={(items) => {
          const [state] = items;
          formik.setFieldValue("address.state", state);
        }}
      />

      {type !== ScreenTypeEnum.PHONE && <Divider />}

      <Button
        title={LocalizedStrings.screens.editPersonalInfo.saveChanges}
        accessibilityLabel={"Save changes"}
        containerStyle={[
          styles.buttonContainer,
          type !== ScreenTypeEnum.PHONE && { alignItems: "flex-end" }
        ]}
        type={type !== ScreenTypeEnum.PHONE ? "outline" : "solid"}
        disabled={!formik.isValid || !formik.dirty || isFalsy(patient)}
        loading={updateMemberLoading || updateMemberDataLoading}
        onPress={
          formik.handleSubmit as unknown as (e: GestureResponderEvent) => void
        }
      />
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    flex1: {
      flex: 1
    },
    tabletTitleContainer: {
      alignItems: "center",
      paddingTop: 20,
      backgroundColor: theme.colors.grey5
    },
    row: {
      flexDirection: "row"
    },
    inputContainer: {
      marginHorizontal: 20,
      gap: 20
    },
    buttonContainer: {
      marginTop: 10,
      margin: 20
    }
  };
});

export default PersonalInfoScreen;
