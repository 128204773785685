import { useState } from "react";
import { View } from "react-native";
import { makeStyles } from "@rneui/themed";

import CheckBox from "../../components/ui/CheckBox";
import DoctorInfo from "../../components/ui/DoctorInfo";
import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";
import Button from "../../components/ui/Button";
import { Alert_show } from "common/helpers/AlertHelper";
import { getNameOrUsername } from "common/helpers/helpers";
import MemberType from "common/types/MemberType";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import { useAppDispatch } from "common/redux";
import LocalizedStrings from "../../helpers/LocalizedStrings";

const getSuccessTitle = ({
  doctors,
  email
}: {
  doctors: MemberType[];
  email: string;
}) => {
  if (email !== undefined)
    return LocalizedStrings.screens.confirmSharing.confirmSharingModalTitle.replace(
      "{{NAME}}",
      email
    );
  else if (doctors.length > 0)
    return LocalizedStrings.screens.confirmSharing.confirmSharingModalTitle.replace(
      "{{NAME}}",
      getNameOrUsername(doctors[0].patient, false, false)
    );
};

const ConfirmSharingScreen = ({ navigation, route }) => {
  const dispatch = useAppDispatch();

  const styles = useStyles();
  const textStyles = useTextStyles();

  const [checked, setChecked] = useState<boolean>(false);

  const { doctors, email } = route.params;

  return (
    <ScreenContainer innerStyle={styles.container}>
      <Text h4 style={textStyles.colorDarkGreyBlue}>
        {LocalizedStrings.screens.confirmSharing.text1}
      </Text>
      {email && (
        <Text body style={textStyles.colorDarkGreyBlue}>
          • {email}
        </Text>
      )}

      {doctors?.map((doctor) => (
        <DoctorInfo key={doctor.patient.patient_id} doctor={doctor} />
      ))}

      <CheckBox
        checked={checked}
        title={LocalizedStrings.screens.confirmSharing.giveConsent}
        onPress={() => setChecked(!checked)}
      />

      <View style={styles.flex1} />

      <Button
        title={LocalizedStrings.screens.confirmSharing.confirmSharing}
        disabled={!checked}
        onPress={() =>
          Alert_show({
            dispatch,
            title: getSuccessTitle({ doctors, email }),
            content:
              LocalizedStrings.screens.confirmSharing
                .confirmSharingModalContent,
            buttons: [
              {
                text: LocalizedStrings.common.close,
                onPress: () => navigation.goBack()
              }
            ]
          })
        }
      />
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      margin: 20,
      gap: 20
    },
    flex1: {
      flex: 1
    }
  };
});

export default ConfirmSharingScreen;
