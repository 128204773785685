import { useState } from "react";
import { makeStyles } from "@rneui/themed";
import { EdgeInsets, useSafeAreaInsets } from "react-native-safe-area-context";

import ScreenContainer from "../components/ui/ScreenContainer";
import Text from "../components/ui/Text";

import Button from "../components/ui/Button";
import useTextStyles from "../components/ui/styles/useTextStyles";
import Spacing from "../components/ui/Spacing";
import UserFeedbackModal from "../components/modal/UserFeedbackModal";

const ErrorScreen = ({ error, resetError }) => {
  const insets = useSafeAreaInsets();
  const styles = useStyles(insets);
  const textStyle = useTextStyles();

  const [isFeedbackDisplayed, setFeedbackDisplayed] = useState<boolean>(false);

  const handleFeedback = () => {
    setFeedbackDisplayed(true);
  };

  return (
    <ScreenContainer innerStyle={styles.container}>
      <Text h1 style={textStyle.colorDarkBlue}>
        Oops!
      </Text>

      <Spacing vertical={2} />

      <Text h4 style={textStyle.colorDarkBlue}>
        An error has occurred.
      </Text>
      <Spacing vertical={4} />

      {error?.message && (
        <Text bodySmall style={textStyle.colorDarkBlue}>
          Error: {error?.message}
        </Text>
      )}

      <Spacing vertical={2} />

      <Button title={"Retry"} onPress={resetError} />
      <Spacing vertical={2} />
      <Button title={"Feedback"} type="outline" onPress={handleFeedback} />
      <UserFeedbackModal
        visible={isFeedbackDisplayed}
        onRequestClose={() => setFeedbackDisplayed(false)}
      />
    </ScreenContainer>
  );
};

const MARGIN_SIDES = 20;

const useStyles = makeStyles((theme, insets: EdgeInsets) => {
  return {
    container: {
      margin: MARGIN_SIDES,
      justifyContent: "center"
    }
  };
});

export default ErrorScreen;
